<template>
    <div class="search__wrapper">
        <div class="search__wrapper__bg"></div>
        <div class="search__wrapper__content">
          <div class="search__wrapper__content__input">
              <a-input-search
                :bordered="false"
                :style="{height: '60px', padding: '0 11px', fontSize: '20px', border: 'none'}"
                v-model:value="value"
                placeholder="搜索内容 SEARCH INFORMATION"
                @search="onSearch"
              />
          </div>
          <SearchAdvance @getSearchList="getSearchList"/>
          <SerachList
            :searchListHeight="searchListHeight"
            :serachList="serachList"
          />
        </div>
        <Footer :cName="cName" />
    </div>
</template>
<script>
import SearchAdvance from './SearchAdvance.vue'
import SerachList from './SearchList.vue'
import Footer from '../components/Footer.vue'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { get } from '../../utils/request'
export default {
  name: 'Search',
  components: { Footer, SearchAdvance, SerachList },
  setup () {
    const value = ref('')
    const searchText = ref('')
    const serachList = ref([])
    // const serachResultList = ref([])
    const searchListHeight = ref('')
    // const searchQueryText = ref()
    const store = useStore()
    const route = useRoute()
    searchText.value = route.query.searchText
    const accessName = computed(() => store.state.accessName)
    const cName = computed(() => store.state.cName)
    const searchTextState = computed(() => store.state.serachList)
    searchListHeight.value = window.innerHeight - 372 + 'px'
    document.title = cName.value
    const getSearchNewsList = async () => {
      if (searchText.value === '') {
        searchText.value = searchTextState.value
      }
      const url = `tableNewsController/searchTableNews?accessName=${accessName.value}&fullSearchText=${searchText.value}`
      // const url = 'tableNewsController/searchTableNews?accessName=kjb&fullSearchText=习近平'
      const result = await get(url)
      serachList.value = result.list
      searchListHeight.value = 'auto'
    }
    onBeforeMount(() => {
      if (searchText.value !== '') {
        value.value = searchText.value
        getSearchNewsList()

        searchListHeight.value = window.innerHeight - 372 + 'px'
      }
    })
    const onSearch = searchValue => {
      serachList.value = []
      searchListHeight.value = window.innerHeight - 372 + 'px'
      searchText.value = searchValue
      store.dispatch('getSearchText', searchValue)
      getSearchNewsList()
    }
    const getSearchList = (data) => {
      searchListHeight.value = 'auto'
      serachList.value = data
    }
    watch(
      searchText.value,
      () => {
        searchListHeight.value = 'auto'
        getSearchNewsList()
      })
    return {
      value,
      cName,
      searchListHeight,
      serachList,
      accessName,
      onSearch,
      getSearchList
    }
  }
}
</script>
<style lang="scss" scoped>
.search__wrapper{
  background-color: #ffffff;
  &__bg{
    height: 250px;
    background-color: #dddddd;
  }
  &__content{
    width: 1200px;
    background-color: #ffffff;
    margin: -70px auto 0;
    &__input{
      height: 70px;
      padding: 10px 20px 0;
      .ant-input-affix-wrapper{
        .ant-input:focus{
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
