<template>
    <div class="newsList__wrapper">
      <p class="newsList__wrapper__p">
        <span>{{cName}}</span>
        <span>{{oneLayoutList.pageNum}}版：</span>
        <span>{{oneLayoutList.pageName}}</span>
        <span>{{oneLayoutList.pageDate}}</span>
      </p>
      <ul class="newsList__wrapper__list">
        <li
          v-for="item in directoryList"
          :key="item.id"
          @click="handleClickNewsList(item.id)"
        >
          <a>
            <h2 v-html="item.title"></h2>
            <p v-html="item.summary.substr(0, 80)+'...'"></p>
          </a>
        </li>
      </ul>
    </div>
</template>
<script>
import { get } from '../../utils/request'
import { ref, watch } from 'vue'
export default {
  name: 'NewsList',
  props: {
    oneLayoutList: Object,
    cName: String,
    newsContentTag: Number
  },
  setup (props, { emit }) {
    const directoryList = ref()
    const newsId = ref()
    const newsContentTag = ref(0)
    const getDirectoryList = async () => {
      const directoryId = props.oneLayoutList.id
      const accessName = props.oneLayoutList.accessName
      const url = `tableNewsController/getTableNewsList?ids=${directoryId}&accessName=${accessName}`
      // const url = `tableNewsController/notRemovalNewsList?ids=${directoryId}&accessName=${accessName}`
      const result = await get(url)
      directoryList.value = result.list
      // result.list.map((item, index) => {
      //   directoryList.value.push(item)
      //   // if (item.typeName !== '附图') {
      //   //   directoryList.value.push(item)
      //   // }
      // })
    }
    const handleClickNewsList = (id) => {
      newsId.value = id
      newsContentTag.value = 1
      emit('getNewsId', newsId.value)
      emit('getIsNewsContent', newsContentTag.value)
    }
    watch(() => {
      getDirectoryList()
    })
    return {
      directoryList,
      getDirectoryList,
      handleClickNewsList
    }
  }
}
</script>
<style lang="scss" scoped>
.newsList__wrapper{
  height: 661px;
  padding-top: 35px;
  &__p{
    margin: 0 24px 10px;
    border-bottom: 1px solid #dddddd;
    span:first-child{
      margin-right: 10px;
      float: left;
      color: #eb4242;
    }
    span:last-child{
      float: right;
    }
  }
  &__list{
    padding: 0 24px;
    overflow: auto;
    height: 582px;
    margin: 0;
    li{
      list-style: none;
      padding-top: 10px;
      border-bottom: 1px solid #dddddd;
      a{
        p{
          text-align: justify;
          color: #000000;
          margin:0;
        }
      }
    }
  }
}
</style>
