<template>
    <div class="searchList__wrapper" :style="{height: searchListHeight}">
        <ul v-if="serachList">
            <li
                v-for="item in serachList"
                :key="item.id"
            >
                <router-link
                    :to="`/searchDetail/${item.id}/${item.accessName}`"
                    target="_blank"
                >
                    <div class="pageDate">
                        <p>-{{item.pageDate.substr(0, 4)}}-</p>
                        <p>
                            {{item.pageDate.substr(5, 2)}}/{{item.pageDate.substr(8, 2)}}
                        </p>
                    </div>
                    <div class="newsDetail">
                        <h2>{{item.title}}</h2>
                        <p class="newsInfo">
                            <span class="cName">{{cName}}</span>
                            <span>第{{item.pageNum}}版</span>
                            <span>{{item.pageName}}</span>
                            <span>作者：{{item.author}}</span>
                        </p>
                        <p class="summary">{{item.summary.substr(0, 120)+'...'}}</p>
                    </div>
                </router-link>
            </li>
        </ul>
        <div
            else
            class="searchList__wrapper__spin"
            :style="{height: searchSpin, paddingTop: '100px'}"
        >
            <a-spin size="large" :style="{marginTop: searchSpin/3}" tip="加载中..."/>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
export default {
  name: 'SerachList',
  props: {
    searchListHeight: String,
    serachList: Array
  },
  setup () {
    const searchSpin = ref()
    const store = useStore()
    const cName = computed(() => store.state.cName)
    searchSpin.value = window.innerHeight - 402 + 'px'

    return {
      cName,
      searchSpin
    }
  }
}
</script>
<style lang="scss" scoped>
.searchList__wrapper{
    width: 1140px;
    margin: 20px auto;
    &__spin{
        text-align: center;
    }
    ul{
        padding: 0;
        li{
            border-bottom: 1px solid #dddddd;
            padding-top: 15px;
            a{
                display: flex;
                .pageDate{
                    text-align: center;
                    width: 56px;
                    height: 60px;
                    background-color: #DE1B23;
                    color: #ffffff;
                    margin-right: 20px;
                    p{
                        margin: 0;
                    }
                    p:first-child{
                        margin-top: 6px;
                    }
                    p:last-child{
                        font-size: 18px;
                    }
                }
                .newsDetail{
                    flex: 1;
                    .newsInfo{
                        .cName{
                            color: #DE1B23;
                        }
                        color: #555555;
                        span{
                            margin-right: 10px;
                        }
                    }
                    .summary{
                        color: #555555;
                    }
                }
            }
        }
    }
}
</style>
