<template>
    <div class="thumbnail__wrapper">
        <div class="swiper-container swiper">
            <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in layoutList"
                  :key="item"
                  @click="handleChangeLayout(index)"
                >
                    <img
                        :src="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.pagePicFile}`"
                        alt=""
                    />
                    <p>
                        <span>第{{item.pageNum}}版:</span>
                        <span>{{item.pageName}}</span>
                        <a
                          :href="apiUrl + `/tablePageController/downPdf?accessName=${item.accessName}&id=${item.id}`"
                          target="_blank"
                        >
                        </a>
                    </p>
                </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            </div>
    </div>
</template>
<script>
import { onMounted } from 'vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.less'
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Navigation
} from 'swiper'
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Navigation])
export default {
  name: 'Swiper',
  props: {
    layoutList: Array
  },
  setup (props, { emit }) {
    const apiUrl = process.env.VUE_APP_API_URL
    const imgUrl = process.env.VUE_APP_UPLOAD_URL
    onMounted(() => {
      // eslint-disable-next-line no-new
      new Swiper('.swiper', {
        slidesPerView: 7,
        spaceBetween: 50,
        slidesPerGroup: 7,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true
        },
        on: {
          navigationShow: function () {
          }
        }
      })
    })
    const handleChangeLayout = (index) => {
      emit('changeLayoutIndex', index)
    }
    return {
      apiUrl,
      imgUrl,
      handleChangeLayout
    }
  }
}
</script>
<style lang="scss" scoped>
.thumbnail__wrapper{
    position: relative;
    margin-top:20px;
    padding: 15px 0;
    background: url('../../assets/swiper_bg.png') repeat;
    .swiper-container{
        width: 1290px;
        margin: 0 auto;
        padding: 0 45px;
        .swiper-button-lock{
            display: block;
        }
        .swiper-button-next{
            width: 30px;
            background: url('../../assets/swiper_next.png') no-repeat;
        }
        .swiper-button-next::after{
            content: ''
        }
        .swiper-button-prev{
            width: 30px;
            background: url('../../assets/swiper_next.png') no-repeat;
            transform: rotate(180deg);
        }
        .swiper-button-prev::after{
            content: ''
        }
        .swiper-slide{
            width: 120px;
            img{
                width: 100%;
            }
            p{
                margin-top:5px;
                a{
                    vertical-align: sub;
                    margin-left: 2px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/pdf.png') center no-repeat;
                }
            }
        }
    }
}
</style>
