<template>
    <div class="hotspot__wrapper" ref="wrapper">
        <img
          v-if="oneLayoutList"
          usemap="#Map"
          :src="`${imgUrl}/${oneLayoutList.accessName}/${oneLayoutList.accessName}/${oneLayoutList.outfilePath}/${oneLayoutList.pagePicFile}`"
          ref="hotspotImg"
          class="hotspot__wrapper__img"
          @load="onload"
          />
        <map name="Map" id="Map">
            <area
              shape="poly"
              v-for="item in newsAreaList"
              :key="item.id"
              :coords="item.coords"
            />
        </map>
        <svg
          class="hotspot__wrapper__svg"
          xmlns="http://www.w3.org/2000/svg"
          :style="{height:svgHeight}"
        >
          <polygon
            v-for="item in newsAreaList"
            :key="item.id"
            :points="item.coords"
            @click="handleHotspotNews(item.id)"
          />
        </svg>
    </div>
</template>
<script>
// import { onMounted } from '@vue/runtime-core'
// import { get } from '../../utils/request'
import { ref } from 'vue'
export default {
  name: 'Hotspot',
  props: {
    oneLayoutList: Object,
    newsAreaList: Array
  },
  setup (props, { emit }) {
    const hotspotImg = ref()
    const wrapper = ref()
    const svgHeight = ref()
    const imgInfo = ref()
    const imgUrl = process.env.VUE_APP_UPLOAD_URL
    const handleHotspotNews = (id) => {
      emit('changeHotspot', id)
    }
    // 图片加载完成后获取图片的高度
    const onload = () => {
      svgHeight.value = hotspotImg.value.offsetHeight
      const imgHeight = hotspotImg.value.offsetHeight
      const imgWidth = hotspotImg.value.offsetWidth
      imgInfo.value = {
        imgHeight: imgHeight,
        imgWidth: imgWidth
      }
      emit('getImgInfo', imgInfo.value)
    }
    return {
      imgUrl,
      hotspotImg,
      wrapper,
      svgHeight,
      onload,
      handleHotspotNews
    }
  }
}
</script>
<style lang="scss" scoped>
.hotspot__wrapper{
  flex: 1;
  text-align: center;
  position: relative;
  &__svg{
    width: 460px;
    top: 0;
    left: 38px;
    position: absolute;
    polygon{
      opacity: 0.3;
      fill: rgba(220,220,220,0);
      stroke: none;
      stroke-dasharray: 1;
    }
    polygon:hover{
      stroke: black;
      fill: #f68a8a;
    }
  }
  &__img{
    width: 460px;
  }
}
</style>
