<template>
    <div ref="layout" class="layout">
        <div class="layout__icon" @click="LayoutListToggle">
            <span class="iconfont">&#xe605;</span>
        </div>
        <span :class="show?'layout__point':'hide'"></span>
        <ul :class="show?'layout__list':'hide'">
            <li
                v-for="(item, index) in layoutList"
                :key="item.id"
            >
                <p>
                    <span
                        @click="changeLayout(index)"
                    >
                        第{{item.pageNum}}版：{{item.pageName}}
                    </span>
                    <a
                        :href="apiUrl + `/tablePageController/downPdf?accessName=${item.accessName}&id=${item.id}`"
                        target="_blank"
                    >
                    </a>
                </p>
            </li>
        </ul>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
// import { get } from '../../utils/request'
export default {
  name: 'LayoutList',
  props: {
    layoutList: Array
  },
  setup (props, { emit }) {
    const apiUrl = process.env.VUE_APP_API_URL
    let url = ''
    const layout = ref()
    const newsContentTag = ref(0)
    const show = ref(false)
    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        if (layout.value && !layout.value.contains(e.target)) {
          show.value = false
        }
      })
    })
    const LayoutListToggle = () => {
      show.value = !show.value
    }
    const changeLayout = (index) => {
      newsContentTag.value = 0
      emit('changeLayout', index)
      emit('changeNewsContentTag', newsContentTag.value)
      show.value = false
    }
    const getPdfInfo = async (accessName, id) => {
      url = process.env.VUE_APP_API_URL + '/tablePageController/downPdf?accessName='
    }
    return {
      apiUrl,
      show,
      url,
      layout,
      LayoutListToggle,
      changeLayout,
      getPdfInfo
    }
  }
}
</script>
<style lang="scss" scoped>
.layout{
    position: absolute;
    top: 120px;
    left: -52px;
    &__point{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 7px;
        left: 45px;
        background: url('../../assets/border_point.png') center no-repeat;
    }
    &__icon{
        width: 52px;
        height: 52px;
        background-color: #ffffff;
        border: 3px solid #eb4242;
        border-radius: 4px;
        text-align:center;
        cursor: pointer;
        .iconfont{
            color: #eb4242;
            font-size: 34px;
        }
    }
    &__list{
        position: absolute;
        top: 0;
        left: 64px;
        width: 240px;
        height: 400px;
        overflow: auto;
        padding: 20px 15px;
        background-color: #ffffff;
        box-shadow: 0 0 2px #888888;
        margin: 0;
        li{
            list-style: none;
            margin: 10px 0;
            cursor: pointer;
            p{
                margin: 0;
                display: flex;
                span{
                   flex: 1;
                    display: inline-block;
                }
                a{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/pdf.png') center no-repeat;
                }
            }
          }
          li:hover{
            font-weight: bold;
          }
    }
    .hide{
        display: none;
    }
}
</style>
