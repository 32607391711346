<template>
    <div class="search">
        <div class="search__input">
            <a-input-search
                v-model:value="value"
                placeholder="请输入搜索内容..."
                enter-button
                @search="onSearch"
            />
        </div>
        <!-- <span class="search__advancedSearch">高级搜索</span> -->
    </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { ref, computed } from 'vue'
import { get } from '../../utils/request'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const value = ref('')
    const searchList = ref([])
    const router = useRouter()
    const searchText = ref('')
    const store = useStore()
    const accessName = computed(() => store.state.accessName)
    const getSearchNewsList = async () => {
      const url = `tableNewsController/searchTableNews?accessName=${accessName.value}&fullSearchText=${searchText.value}`
      // const url = 'tableNewsController/searchTableNews?accessName=kjb&fullSearchText=习近平'
      const result = await get(url)
      searchList.value = result.list
      store.dispatch('getSerachList', searchList.value)
    }
    const searchInfo = () => {
      message.info('请输入搜索内容！')
    }
    const onSearch = (searchValue) => {
      if (searchValue !== '') {
        searchText.value = searchValue
        getSearchNewsList()
        const routeUrl = router.resolve({
          path: '/search',
          query: {
            searchText: searchText.value
          }
        })
        window.open(routeUrl.href, '_blank')
      } else {
        searchInfo()
      }
    }

    return {
      value,
      onSearch
    }
  }
}
</script>
<style lang="scss" scoped>
.search{
        position: absolute;
        right: 0;
        top: -39px;
        &__input{
            display: inline-block;
            vertical-align: middle;
        }
        &__advancedSearch{
            color: #eb4242;
            line-height: 32px;
        }
}
</style>
