<template>
    <div class="container">
        <div class="container__wrapper">
            <Logo />
            <div class="container__wrapper__book">
                <div class="container__wrapper__book__btn prev" @click="handleClickPrev">上一版</div>
                <div class="container__wrapper__book__btn next" @click="handleClickNext">下一版</div>
                <div class="container__wrapper__book__btn prevDate" @click="handleClickPrevDate">上一期</div>
                <div class="container__wrapper__book__btn nextDate" @click="handleClickNextDate">下一期</div>
                <SearchInput />
                <div
                  class="container__wrapper__book__content"
                >
                  <Hotspot
                    :oneLayoutList="oneLayoutList"
                    :newsAreaList="newsAreaList"
                    @changeHotspot="changeHotspot"
                    @getImgInfo="getImgInfo"
                  />
                  <div class="container__wrapper__book__content__right">
                    <Back @goBack="goBack" :class="newsContentTag===1?'':'hidden'"/>
                    <div :class="newsContentTag===0?'':'hidden'">
                      <NewsList
                        v-if="oneLayoutList"
                        :cName="cName"
                        @getNewsId="getNewsId"
                        @getIsNewsContent="getIsNewsContent"
                        :oneLayoutList="oneLayoutList"
                        :newsContentTag="newsContentTag"
                      />
                    </div>
                    <div :class="newsContentTag===1?'':'hidden'">
                      <NewsDetail
                        v-if="newsId"
                        :accessName="accessName"
                        :newsId="newsId"
                        :newsContentTag="newsContentTag"
                        :qrcode="qrcode"
                      />
                    </div>
                  </div>
                </div>
                <LayoutList
                  v-if="layoutList"
                  :layoutList="layoutList"
                  @changeLayout="changeLayout"
                  @changeNewsContentTag="changeNewsContentTag"
                />
                <Calender
                  ref="calender"
                  v-if="periodical == 0"
                  :show="show"
                  :newspaperId="newspaperId"
                  @changePageDate="changePageDate"
                />
                <Periodical
                  v-else-if="newspaperId"
                  :newspaperId="newspaperId"
                  @changePageDate="changePageDate"
                />
                <Mobile
                  :show="show"
                />
            </div>
        </div>
        <Swiper :layoutList="layoutList" @changeLayoutIndex="changeLayoutIndex"/>
        <Footer :cName="cName" />
    </div>
</template>
<script>
import Logo from '../../components/Logo.vue'
import Hotspot from './Hotspot.vue'
import NewsList from './NewsList.vue'
import NewsDetail from '../components/NewsDetail.vue'
import SearchInput from '../search/SearchInput.vue'
import LayoutList from './LayoutList.vue'
import Mobile from './Mobile.vue'
import Footer from '../components/Footer.vue'
import Back from '../components/Back.vue'
import Swiper from './Swiper.vue'
import { get } from '../../utils/request'
import { ref, watch, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Calender from './Calender.vue'
import Periodical from './Periodical.vue'
import { message } from 'ant-design-vue'
export default {
  name: 'Home',
  components: {
    Logo,
    Hotspot,
    NewsList,
    SearchInput,
    LayoutList,
    Calender,
    Periodical,
    Mobile,
    NewsDetail,
    Swiper,
    Footer,
    Back
  },
  setup (props, { emit }) {
    const show = ref()
    const cName = ref()
    const newsId = ref()
    const newspaperId = ref()
    const accessName = ref(null)
    const calender = ref(null)
    const oneLayoutList = ref()
    const newsAreaOriginal = ref()
    const newsAreaList = ref([])
    const layoutList = ref()
    const layoutIndex = ref(0)
    const newsContentTag = ref(0)
    const pageDate = ref()
    const periodical = ref()
    const imgInfo = ref()
    const imgRatio = ref()
    let temNewsAreaList = reactive()
    // const pageDateList = ref([])
    const store = useStore()
    const route = useRoute()
    accessName.value = route.params.accessName
    store.dispatch('getNewspaper', accessName.value)
    const getNewspaperCName = async () => {
      const url = `newspaperController/getNewspaper?name=${accessName.value}`
      const result = await get(url)
      cName.value = result.data.cname
      document.title = cName.value
      newspaperId.value = result.data.id
      periodical.value = result.data.periodical
      store.dispatch('getCName', cName.value)
    }
    getNewspaperCName()
    const getImgInfo = (data) => {
      // 获取实际渲染的图片的大小
      imgInfo.value = data
      // 计算热区坐标点的比率
      const pagePicHeight = oneLayoutList.value.pagePicHeight
      const pagePicWidth = oneLayoutList.value.pagePicWidth
      if (imgInfo.value !== undefined) {
        const heightRatio = pagePicHeight / imgInfo.value.imgHeight
        const widthRatio = pagePicWidth / imgInfo.value.imgWidth
        imgRatio.value = {
          heightRatio: heightRatio,
          widthRatio: widthRatio
        }
        newsAreaOriginal.value = JSON.parse(oneLayoutList.value.newsArea)
        newsAreaList.value = []
        for (let i = 0; i < newsAreaOriginal.value.length; i++) {
          const id = newsAreaOriginal.value[i].ID
          const temporaryList = newsAreaOriginal.value[i].COORDS.split(',')
          const countCoordsList = countRatio(temporaryList)
          temNewsAreaList = countCoordsList.join(',')
          newsAreaList.value.push({
            id: id,
            coords: temNewsAreaList
          })
        }
      }
      // getLayoutList()
    }
    const getLayoutList = async () => {
      const url = `tablePageController/getTablePageList?pageDate=${pageDate.value}&accessName=${accessName.value}`
      const result = await get(url)
      if (result.list.length !== 0) {
        layoutList.value = result.list
        oneLayoutList.value = layoutList.value[layoutIndex.value]
      }
    }
    // 重新计算坐标点的位置
    const countRatio = (arr) => {
      const itemList = ref([])
      var countRetio = ref()
      for (var j = 0, len = arr.length; j < len; j++) {
        if (j % 2 === 0) {
          countRetio.value = arr[j] / imgRatio.value.widthRatio
        } else {
          countRetio.value = arr[j] / imgRatio.value.heightRatio
        }
        itemList.value.push(countRetio.value.toFixed(0))
      }
      return itemList.value
    }
    const changeLayout = (data) => {
      layoutIndex.value = data
      show.value = true
    }
    const changeLayoutIndex = (data) => {
      layoutIndex.value = data
      newsContentTag.value = 0
      document.title = cName.value
    }
    const getNewsId = (data) => {
      newsId.value = data
    }
    const getIsNewsContent = (data) => {
      newsContentTag.value = data
    }
    const goBack = (data) => {
      newsContentTag.value = data
    }
    const changeNewsContentTag = (data) => {
      newsContentTag.value = data
    }
    const changeHotspot = (data) => {
      newsId.value = data
      newsContentTag.value = 1
    }
    const changePageDate = (data) => {
      pageDate.value = data
      getLayoutList()
    }
    const nextInfo = () => {
      message.info('当前已经是最后一版！')
    }
    const prevInfo = () => {
      message.info('当前已经是第一版！')
    }
    const handleClickNext = () => {
      const length = layoutList.value.length
      const newIndex = layoutIndex.value + 1
      if (newIndex < length) {
        newsContentTag.value = 0
        changeLayout(newIndex)
        changeNewsContentTag(newsContentTag.value)
      } else {
        nextInfo()
      }
    }
    const handleClickPrev = () => {
      // const length = layoutList.value.length
      const newIndex = layoutIndex.value - 1
      if (newIndex >= 0) {
        newsContentTag.value = 0
        changeLayout(newIndex)
        changeNewsContentTag(newsContentTag.value)
      } else {
        prevInfo()
      }
    }
    const handleClickPrevDate = () => {
      newsContentTag.value = 0
      calender.value.changePrevData()
    }
    const handleClickNextDate = () => {
      newsContentTag.value = 0
      calender.value.changeNextData()
    }
    watch(
      () => layoutIndex.value,
      () => {
        getLayoutList()
      })
    return {
      show,
      cName,
      newsId,
      newspaperId,
      accessName,
      imgRatio,
      newsContentTag,
      oneLayoutList,
      layoutList,
      periodical,
      newsAreaList,
      calender,
      getLayoutList,
      changeLayout,
      getImgInfo,
      getNewsId,
      getIsNewsContent,
      goBack,
      changeNewsContentTag,
      changeHotspot,
      changeLayoutIndex,
      changePageDate,
      handleClickPrev,
      handleClickNext,
      handleClickPrevDate,
      handleClickNextDate
    }
  }
}
</script>
<style lang="scss" scoped>
.hidden{
    display:none;
}
.container{
    &__wrapper{
        width: 1080px;
        margin: 0 auto;
        &__book{
            position: relative;
            margin-top: 10px;
            &__btn{
              position: absolute;
              width: 35px;
              background-color: #eb4242;
              display: inline-block;
              color: #ffffff;
              padding: 10px;
              cursor: pointer;
            }
            .prev{
              top: 100px;
              right: -35px;
            }
            .next{
              top: 215px;
              right: -35px;
            }
            .prevDate{
              left: -35px;
              top: 380px;
            }
            .nextDate{
              left: -35px;
              top: 500px;
            }
            &__content{
                display: flex;
                background-color: #ffffff;
                border: 1px solid #dddddd;
                width: 100%;
                &__skeleton{
                  height: 600px;
                  width: 1000px;
                }
                &__right{
                  position: relative;
                  flex: 1;
                  background: url('../../assets/book_bg.jpg') left repeat-y;
                }
            }
        }
    }
}
</style>
