import axios from 'axios'

const instance = axios.create({
  baseURL: '/api',
  timeout: 10000
})

export const get = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then((response) => {
      resolve(response.data)
    })
  })
}
