<template>
    <div class="container__footer">{{cName}}版权所有</div>
</template>
<script>
export default {
  name: 'Footer',
  props: {
    cName: String
  }
}
</script>
<style lang="scss" scoped>
.container__footer{
    height: 47px;
    line-height: 47px;
    text-align: center;
    color: #ffffff;
    background-color: #877e7e;
}
</style>
