<template>
    <div id="newsDetail__wrapper" class="newsDetail__wrapper">
        <!-- <div
            class="newsDetail__wrapper__img"
            v-if="typeName === '图片'"
        >
            <img
                :src="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.fileName}`"
            />
            <div v-html="item.picExplain"></div>
        </div> -->
        <div class="newsDetail__wrapper__text">
            <div class="newsDetail__wrapper__text__alltitle">
              <h4 class="primersTitle">
                {{primersTitle}}
              </h4>
              <h1 class="title" v-html="title">
              </h1>
              <h4 class="subtitle">
                {{subtitle}}
              </h4>
              <p class="author">
                {{author}}
              </p>
            </div>
            <div class="newsDetail__wrapper__text__tips">
              <span>日期：{{pageDate}}</span>
              <span>版次：{{pageNum}}</span>
              <FontScaling
                v-if="newsContent"
                @changeFontSizeAdd="changeFontSizeAdd"
                @changeFontSizeSubtract="changeFontSizeSubtract"
              />
              <WechatShare :newsId="newsId" :accessName="accessName"/>
              <Vioce :voiceText="voiceText"/>
              <SelectLink :newsId="newsId" :accessName="accessName" />
            </div>
            <!-- 图片稿件 -->
            <div
              class="newsDetail__wrapper__text__img"
              v-if="typeName === '图片'"
            >
            <a
                    target="_blank"
                    :href="`${imgUrl}/${accessName}/${accessName}/${outfilePath}/${standardFile}`">
              <img
                :src="`${imgUrl}/${accessName}/${accessName}/${outfilePath}/${standardFile}`"
              />
              </a>
              <p>{{picExplain}}</p>
            </div>
            <!-- 附图 -->
            <div
                v-else
                class="newsDetail__wrapper__text__img"
                v-for="(item, index) in attachedPictures"
                :key="index"
            >
                <a
                    target="_blank"
                    :href="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.standardFile}`">
                  <img
                    :src="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.standardFile}`"
                  />
                </a>
                <div v-html="item.picExplain"></div>
            </div>
            <div
              class="newsDetail__wrapper__text__content"
              ref="text"
              v-html="newsContent"
            >
            </div>
        </div>
    </div>
</template>
<script>
import { get } from '../../utils/request'
import { ref, watch } from 'vue'
import FontScaling from '../home/FontScaling.vue'
import SelectLink from './SelectLink.vue'
import WechatShare from '../home/WxShare.vue'
import Vioce from '../components/Vioce.vue'
import { useRoute } from 'vue-router'
export default {
  name: 'NewsDetail',
  components: { Vioce, FontScaling, WechatShare, SelectLink },
  props: {
    newsId: String,
    accessName: String,
    newsContentTag: Number
  },
  setup (props, { emit }) {
    const newsContent = ref()
    const primersTitle = ref()
    const title = ref()
    const subtitle = ref()
    const author = ref()
    const pageNum = ref()
    const pageDate = ref()
    const typeName = ref()
    const outfilePath = ref()
    const standardFile = ref()
    const picExplain = ref()
    const attachedPictures = ref([])
    const voiceText = ref()
    const count = ref(18)
    const text = ref(null)
    const stop = ref()
    const newsId = ref()
    const attachedList = ref()
    const accessName = ref()
    const newsDetailContent = ref()
    const route = useRoute()
    const imgUrl = process.env.VUE_APP_UPLOAD_URL
    const Base64 = require('js-base64').Base64
    const getNewsDetail = async () => {
      if (props.newsId || props.accessName) {
        newsId.value = props.newsId
        accessName.value = props.accessName
      } else {
        newsId.value = route.params.newsId
        accessName.value = route.params.accessName
      }
      const url = `tableNewsController/getTableNews?id=${newsId.value}&accessName=${accessName.value}`
      const result = await get(url)
      primersTitle.value = result.data.primersTitle
      title.value = result.data.title
      document.title = title.value
      subtitle.value = result.data.subtitle
      author.value = result.data.author
      pageNum.value = result.data.pageNum
      pageDate.value = result.data.pageDate
      typeName.value = result.data.typeName
      outfilePath.value = result.data.outfilePath
      standardFile.value = result.data.standardFile
      picExplain.value = result.data.picExplain
      // attachedPictures.value = result.data.attachedPictures
      if (result.data.attachedPictures !== undefined) {
        attachedPictures.value = []
        result.data.attachedPictures.map((item, index) => {
          const picExplain = Base64.decode(item.picExplain)
          attachedList.value = {
            accessName: item.accessName,
            outfilePath: item.outfilePath,
            fileName: item.fileName,
            standardFile: item.standardFile,
            picExplain: picExplain
          }
          attachedPictures.value.push(attachedList.value)
        })
      }
      newsContent.value = Base64.decode(result.data.content)
      voiceText.value = (primersTitle.value + title.value + subtitle.value + newsContent.value).replace(/<[^>]+>/g, '')
    }
    // getNewsDetail()
    const changeFontSizeAdd = () => {
      if (count.value < 24) {
        count.value += 2
        text.value.style.fontSize = count.value + 'px'
      }
    }
    const changeFontSizeSubtract = () => {
      if (count.value > 16) {
        count.value -= 2
        text.value.style.fontSize = count.value + 'px'
      }
    }
    watch(() => {
      getNewsDetail()
    })
    return {
      primersTitle,
      title,
      subtitle,
      pageDate,
      typeName,
      pageNum,
      outfilePath,
      standardFile,
      picExplain,
      imgUrl,
      text,
      stop,
      author,
      newsDetailContent,
      newsContent,
      voiceText,
      attachedPictures,
      changeFontSizeAdd,
      changeFontSizeSubtract
    }
  }
}
</script>
<style lang="scss" scoped>
.newsDetail__wrapper{
    height: 674px;
    overflow-y: auto;
    position: relative;
    padding: 60px 0 0;
    flex: 1;
    &__text{
      &__alltitle{
        text-align: center;
        padding: 0 15px;
      }
      &__tips{
        box-sizing: border-box;
        padding: 0 30px;
        position: relative;
        margin-bottom: 10px;
        border-bottom: 1px solid #dddddd;
        span{
          margin-right: 10px;
        }
      }
      &__img{
        width: 90%;
        margin: 0 auto;
        text-align: center;
        img{
          width: 100%;
          margin-bottom: 10px;
        }
      }
      &__content{
        font-size: 18px;
        padding-left: 30px;
        word-break: break-all;
      }
    }
}
</style>
