<template>
    <div class="searchDetail__wrapper">
        <div class="searchDetail__wrapper__logo">
            <Logo />
        </div>
        <div class="searchDetail__wrapper__bg"></div>
        <div class="searchDetail__wrapper__content">
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <a :href="`#/${accessName}`" target="_blank">首页</a>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <span>搜索结果</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item >文章详情</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="searchDetail__wrapper__content__detail">
                <div class="pageDate" v-if="newsData">
                    <p>-{{newsData.pageDate.substr(0, 4)}}-</p>
                    <p>
                        {{newsData.pageDate.substr(5, 2)}}/{{newsData.pageDate.substr(8, 2)}}
                    </p>
                </div>
                <div
                    class="newsDetail__img"
                    v-if="typeName === '图片'"
                >
                    <img />
                </div>
                <div class="newsDetail" v-else>
                    <h4
                        class="newsDetail__wrapper__text__primersTitle"
                        v-if="newsData"
                    >
                    {{newsData.primersTitle}}
                    </h4>
                    <h1 v-if="newsData">{{newsData.title}}</h1>
                    <h4 class="newsDetail__wrapper__text__subtitle" v-if="newsData">
                    {{newsData.subtitle}}
                    </h4>
                    <div class="newsInfo" v-if="newsData">
                        <span class="cName">{{newsData.source}}</span>
                        <span>【第{{newsData.pageNum}}版</span>
                        <span>{{newsData.pageName}}】</span>
                        <span>【作者：{{newsData.author}}】</span>
                        <FontScaling
                            v-if="newsText"
                            @changeFontSizeAdd="changeFontSizeAdd"
                            @changeFontSizeSubtract="changeFontSizeSubtract"
                        />
                        <WechatShare :newsId="newsData.id" :accessName="newsData.accessName"/>
                        <Vioce :voiceText="voiceText"/>
                    </div>
                    <div
                        class="newsText__img"
                        v-for="(item, index) in attachedPictures"
                        :key="index"
                    >
                        <img
                            :src="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.fileName}`"
                        />
                        <div v-html="item.picExplain"></div>
                    </div>
                    <div ref="text" class="newsText" v-html="newsText"></div>
                </div>
            </div>
        </div>
        <Footer v-if="newsData" :cName="cName" />
    </div>
</template>
<script>
import Logo from '../../components/Logo.vue'
import FontScaling from '../home/FontScaling.vue'
import WechatShare from '../home/WxShare.vue'
import Vioce from '../components/Vioce.vue'
import Footer from '../components/Footer.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { get } from '../../utils/request'
export default {
  name: 'SearchDetail',
  props: {
  },
  components: {
    Logo,
    FontScaling,
    WechatShare,
    Vioce,
    Footer
  },
  setup () {
    const newsData = ref()
    const newsText = ref()
    const voiceText = ref()
    const count = ref(18)
    const text = ref(null)
    const newsTextImg = ref(null)
    const attachedPictures = ref([])
    const attachedList = ref([])
    const imgUrl = process.env.VUE_APP_UPLOAD_URL
    const route = useRoute()
    const Base64 = require('js-base64').Base64
    const newsId = route.params.newsId
    const accessName = route.params.accessName
    const store = useStore()
    const cName = computed(() => store.state.cName)
    document.title = '稿件详情'
    const getSearchNewsDetail = async () => {
      const url = `tableNewsController/getTableNews?id=${newsId}&accessName=${accessName}`
      // const url = 'tableNewsController/searchTableNews?accessName=kjb&fullSearchText=习近平'
      const result = await get(url)
      newsData.value = result.data
      const primersTitle = newsData.value.primersTitle
      const title = newsData.value.title
      const subtitle = newsData.value.subtitle
      result.data.attachedPictures.map((item, index) => {
        const picExplain = Base64.decode(item.picExplain)
        attachedList.value = {
          accessName: item.accessName,
          outfilePath: item.outfilePath,
          fileName: item.fileName,
          picExplain: picExplain
        }
        attachedPictures.value.push(attachedList.value)
      })
      newsText.value = Base64.decode(newsData.value.content)
      voiceText.value = (primersTitle + title + subtitle + newsText.value).replace(/<[^>]+>/g, '')
    }
    getSearchNewsDetail()
    const changeFontSizeAdd = () => {
      if (count.value < 24) {
        count.value += 2
        text.value.style.fontSize = count.value + 'px'
      }
    }
    const changeFontSizeSubtract = () => {
      if (count.value > 16) {
        count.value -= 2
        text.value.style.fontSize = count.value + 'px'
      }
    }
    return {
      text,
      accessName,
      newsData,
      newsText,
      imgUrl,
      cName,
      newsTextImg,
      attachedPictures,
      voiceText,
      changeFontSizeAdd,
      changeFontSizeSubtract
    }
  }
}
</script>
<style lang="scss" scoped>
.searchDetail__wrapper{
    &__logo{
        margin: 1% 0 1% 10%;
    }
    &__bg{
        width: 90%;
        height: 182px;
        margin-left: 10%;
        background-color: #DE1B23;
    }
    &__content{
        width: 80%;
        margin: -157px auto 0;
        background-color: #ffffff;
        padding-top: 15px;
        &__detail{
            display: flex;
            margin-top: 20px;
            .pageDate{
                p{
                    margin: 0;
                }
                p:first-child{
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
                p:last-child{
                    font-size: 18px;
                }
            }
            .newsInfo{
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 15px;
                border-bottom: 1px solid #dddddd;
                span{
                    margin-right: 10px;
                }
            }
            .pageDate{
                text-align: center;
                width: 68px;
                height: 68px;
                background-color: #DE1B23;
                color: #ffffff;
                margin-right: 20px;
            }
            .newsDetail{
                width: 91%;
                text-align: center;
                .newsText__img{
                    width: 100%;
                    margin-bottom: 15px;
                    img{
                        width: 500px;
                    }
                }
                .newsText{
                    text-align: justify;
                    font-size: 18px;
                    padding-top: 20px;
                }
            }
        }
    }
}
</style>
