<template>
    <div class="back__wrapper" @click="handleclickBack"></div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'Back',
  setup (props, { emit }) {
    const store = useStore()
    const cName = computed(() => store.state.cName)
    const handleclickBack = () => {
      emit('goBack', 0)
      document.title = cName.value
    }
    return {
      handleclickBack
    }
  }
}
</script>
<style lang="scss" scoped>
.back__wrapper{
    cursor: pointer;
    position: absolute;
    top: -6px;
    left: 0;
    height: 39px;
    width: 62px;
    z-index: 999;
    background: url('../../assets/back.png') no-repeat;
}
</style>
