<template>
  <div class="searchAdvance__wrapper">
    <a-form
      ref="formRef"
      name="advanced_search"
      class="ant-advanced-search-form"
      :model="formState"
      @finish="onFinish"
    >
      <a-row :gutter="12">
          <a-col v-show="expand" :span="12">
            <a-form-item name="keyword" label="关键字">
            <a-input
              v-model:value="formState['keyword']"
              placeholder=""
            />
            </a-form-item>
            <a-form-item name="range-picker" label="发布日期" v-bind="rangeConfig">
              <a-range-picker
                v-model:value="formState['picker']"
                value-format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
              />
            </a-form-item>
            <a-form-item name="author" label="作者">
            <a-input v-model:value="formState['author']" />
            </a-form-item>
            <a-form-item name="title" label="标题">
            <a-input v-model:value="formState['title']" />
            </a-form-item>
            <a-form-item name="pageName" label="版名">
            <a-input v-model:value="formState['pageName']" />
            </a-form-item>
            <a-form-item name="column" label="栏目">
            <a-input v-model:value="formState['column']" />
            </a-form-item>
          </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" style="text-align: left">
          <a-button type="primary" html-type="submit">搜索</a-button>
          <a-button style="margin: 0 8px" @click="() => formRef.resetFields()">清空</a-button>
          <a style="font-size: 12px;color: #DE1B23" @click="expand = !expand">
            <template v-if="expand">
              <UpOutlined />
            </template>
            <template v-else>
              <DownOutlined />
            </template>
            高级搜索
          </a>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { get } from '../../utils/request'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    DownOutlined,
    UpOutlined
  },

  setup (props, { emit }) {
    const expand = ref(false)
    const formRef = ref()
    const serachList = ref([])
    const store = useStore()
    const keyword = ref('')
    const beginPageDate = ref()
    const endPageDate = ref()
    const author = ref('')
    const title = ref('')
    const pageName = ref('')
    const column = ref('')
    // const defaultDate = reactive(['2022-03-03', '2022-03-06'])
    const formState = reactive({})
    const accessName = computed(() => store.state.accessName)
    const getSearchNewsList = async () => {
      if (formState.picker) {
        beginPageDate.value = formState.picker[0]
        endPageDate.value = formState.picker[1]
      } else {
        beginPageDate.value = ''
        endPageDate.value = ''
      }
      if (formState.keyword) {
        keyword.value = formState.keyword
      } else {
        keyword.value = ''
      }
      if (formState.author) {
        author.value = formState.author
      } else {
        author.value = ''
      }
      if (formState.title) {
        title.value = formState.title
      } else {
        title.value = ''
      }
      if (formState.pageName) {
        pageName.value = formState.pageName
      } else {
        pageName.value = ''
      }
      if (formState.column) {
        column.value = formState.column
      } else {
        column.value = ''
      }
      const url = `tableNewsController/searchTableNews?accessName=${accessName.value}&fullSearchText=${keyword.value}&beginPageDate=${beginPageDate.value}&endPageDate=${endPageDate.value}&author=${author.value}&title=${title.value}&pageName=${pageName.value}&columnName=${column.value}`
      // const url = 'tableNewsController/searchTableNews?accessName=kjb&fullSearchText=习近平'
      const result = await get(url)
      serachList.value = result.list
      emit('getSearchList', serachList.value)
    }
    const onFinish = values => {
      getSearchNewsList()
    }

    return {
      formRef,
      formState,
      expand,
      serachList,
      onFinish,
      getSearchNewsList
    }
  }

})
</script>
<style>
#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
[data-theme='dark'] .ant-advanced-search-form {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid #434343;
  padding: 24px;
  border-radius: 2px;
}
[data-theme='dark'] #components-form-demo-advanced-search .search-result-list {
  border: 1px dashed #434343;
  background: rgba(255, 255, 255, 0.04);
}
.searchAdvance__wrapper{
    width: 1140px;
    margin: 10px auto;
    padding-top: 20px;
    border-top: 2px solid #DE1B23;
}
</style>
