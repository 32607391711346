<template>
    <span class="iconfont" @click="handleClickFontAdd">&#xe681;</span>
    <span class="iconfont" @click="handleClickFontSub">&#xe682;</span>
</template>
<script>
export default {
  name: 'FontScaling',
  setup (props, { emit }) {
    const handleClickFontAdd = () => {
      emit('changeFontSizeAdd')
    }
    const handleClickFontSub = () => {
      emit('changeFontSizeSubtract')
    }
    return {
      handleClickFontAdd,
      handleClickFontSub
    }
  }
}
</script>
<style lang="scss" scoped>
.iconfont{
    font-size: 18px;
    color: #eb4242;
    margin-right: 10px;
    cursor: pointer;
}
</style>
