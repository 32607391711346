<template>
    <ul class="newspaperList">
        <li>
            <!-- 锡林郭勒日报 -->
            <a
                href="https://www.xlglrbw.org.cn/library/b/library/page/digitalResource"
                target="_blank"
            >
                <img
                    :src="`${logoSrc}/xlglrb${imgType}`"
                />
            </a>
        </li>
        <li
            v-for="(item, index) in newspaperList"
            :key="index"
        >
            <router-link
                :to="`${web}/${item}`"
                target="_blank"
            >
                <img
                    :src="`${logoSrc}/${item}${imgType}`"
                />
            </router-link>
        </li>
    </ul>
</template>
<script>
import { ref } from 'vue'
export default {
  setup () {
    document.title = '报纸列表'
    // const protocol = window.document.location.protocol
    // const host = window.document.location.host

    // const web = protocol + '//' + host
    const href = window.location.href
    const index = window.location.href.indexOf('#')
    const web = href.substr(0, index - 1)
    // debugger
    const logoSrc = process.env.VUE_APP_UPLOAD_URL + '/list_logo'
    const imgType = '_logo.jpg'
    const newspaperList = ref(['ddsh', 'zdrb', 'tlrb', 'cfdb', 'sgrbjtb', 'tjgrb', 'ycgbdsb', 'zghyqk', 'hengsrb', 'hengswb', 'ddbt', 'sxjtb', 'kjb', 'njb', 'bynewb', 'bynerb', 'ndfzxk', 'clsx', 'cclgdxb'])
    return {
      logoSrc,
      imgType,
      web,
      newspaperList
    }
  }
}
</script>
<style lang="scss">
.newspaperList{
    width: 1200px;
    padding-left: 0;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    li{
        margin: 30px 50px;
        img{
            width: 200px;
        }
    }
}
</style>
