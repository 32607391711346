<template>
    <div ref="calender" class="calender">
        <div class="calender__icon" @click="CalenderToggle">
            <span class="iconfont">&#xe8cf;</span>
        </div>
        <span :class="show?'calender__point':'hide'"></span>
        <div :class="show?'calender__wrapper':'hide'">
            <div :style="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }">
                <a-calendar
                  :calendar="1"
                  v-model:value="value"
                  :fullscreen="false"
                  :disabled-date="disabledDate"
                  @select="onSelect"
                  @panelChange="onPanelChange" />
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { get } from '../../utils/request'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import 'moment/locale/zh-cn'
export default {
  name: 'Calender',
  props: {
    layoutList: Array,
    newspaperId: String
  },
  setup (props, { emit }) {
    const show = ref(false)
    const value = ref()
    const calender = ref(1)
    const pageDate = ref()
    const pageDateIndex = ref(null)
    const pageDateList = ref([])
    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        if (calender.value && !calender.value.contains(e.target)) {
          show.value = false
        }
      })
    })
    const CalenderToggle = () => {
      show.value = !show.value
    }
    const getPageDateList = async () => {
      const url = `pageDateController/getPageDateList?newspaperId=${props.newspaperId}`
      const result = await get(url)
      result.list.map((item, index) => {
        const pageDate = item.pageDate
        pageDateList.value.push(pageDate)
      })
      pageDate.value = pageDateList.value[0]
      value.value = pageDate.value
      emit('changePageDate', pageDate.value)
    }
    getPageDateList()
    const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const disabledDate = (current) => {
      let selectDate = false
      selectDate = !pageDateList.value.some((son, index, arr) => {
        return current.isSame(son, 'day')
      })
      return selectDate
    }
    const onSelect = (date) => {
      const prevDate = new Date(date)
      const newDate = formatDate(prevDate)
      emit('changePageDate', newDate)
      show.value = false
    }

    const changeNextData = () => {
      pageDateList.value.map((item, index) => {
        if (item === pageDate.value) {
          pageDateIndex.value = index
        }
      })
      if (pageDateIndex.value > 0) {
        const newPageDate = pageDateIndex.value - 1
        pageDate.value = pageDateList.value[newPageDate]
        emit('changePageDate', pageDate.value)
        value.value = pageDate.value
      } else {
        nextDateInfo()
      }
    }
    const changePrevData = () => {
      // debugger
      pageDateList.value.map((item, index) => {
        // debugger
        if (item === pageDate.value) {
          pageDateIndex.value = index
          // debugger
        }
      })
      if (pageDateIndex.value < pageDateList.value.length) {
        const newPageDate = pageDateIndex.value + 1
        pageDate.value = pageDateList.value[newPageDate]
        emit('changePageDate', pageDate.value)
        value.value = pageDate.value
      } else {
        prevDateInfo()
      }
    }
    const nextDateInfo = () => {
      message.info('当前已经是最新一期！')
    }
    const prevDateInfo = () => {
      message.info('当前已经是第一期！')
    }
    // defineExpose({
    //   changeLayoutData()
    // })
    return {
      show,
      value,
      dayjs,
      calender,
      CalenderToggle,
      onSelect,
      disabledDate,
      changePrevData,
      changeNextData
    }
  }
}
</script>
<style lang="scss" scoped>
.calender{
    position: absolute;
    top: 200px;
    left: -52px;
    &__point{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 8px;
        left: 45px;
        background: url('../../assets/border_point.png') center no-repeat;
    }
    &__icon{
        width: 52px;
        height: 52px;
        background-color: #ffffff;
        border-radius: 4px;
        text-align:center;
        border: 3px solid #eb4242;
        cursor: pointer;
        .iconfont{
            color: #eb4242;
            font-size: 34px;
        }
    }
    &__wrapper{
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: 63px;
    }
    .hide{
        display: none;
    }
}
</style>
