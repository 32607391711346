<template>
  <img
    :src="`${logoSrc}/${newspaper}`"
    :style="{width: '200px'}"
  />
</template>
<script>
import { useRoute } from 'vue-router'
export default {
  name: 'Logo',
  setup () {
    const route = useRoute()
    const newspaper = route.params.accessName + '_logo.jpg'
    const logoSrc = process.env.VUE_APP_UPLOAD_URL + '/logo'
    return {
      logoSrc,
      newspaper
    }
  }
}
</script>
