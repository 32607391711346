<template>
  <div ref="voice" class="voice" @click="readNewspaper(voiceText)">
    <div class="voice__voiceIcon">
      <span v-if="stop" class="iconfont">&#xe96c;</span>
      <span v-else class="iconfont">&#xe96b;</span>
      <span class="voice__voiceIcon__text">语音读报</span>
      <audio ref="audio" id="audio" src=""></audio>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { get } from '../../utils/request'
export default {
  name: 'Voice',
  props: {
    voiceText: String
  },
  setup () {
    const tok = ref()
    const textVoiceList = ref([])
    const textList = ref([])
    const audio = ref()
    const voice = ref()
    const stop = ref(true)
    // const audio = document.getElementById('audio')
    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        if (voice.value && !voice.value.contains(e.target)) {
          audio.value.pause()
          stop.value = true
        }
      })
    })
    const readNewspaper = async (text) => {
      stop.value = false
      const url = 'CommonController/getTok'
      const result = await get(url)
      tok.value = result.data.access_token
      if (text !== '') {
        textList.value = text.split(/[。]|[！]|[？]|\s{2,}|\n/)
        // textList.value = textList.value.notempty()
      }
      speakText(textList.value)
    }
    const playEndedHandler = () => {
      audio.value.src = textVoiceList.value.shift()
      audio.value.play()
      !textVoiceList.value.length && audio.value.removeEventListener('ended', playEndedHandler, false) // 只有一个元素时解除绑定
      if (!textVoiceList.value.length) {
        stop.value = true
      }
    }
    const randomString = (len) => {
      len = len || 32
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
      var maxPos = $chars.length
      var pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    }
    const speakText = () => {
      // 去除数组中的空值
      textList.value.forEach((item, index) => {
        if (item === '') {
          textList.value.splice(index, 1)
        }
      })
      textVoiceList.value = []
      for (let i = 0; i < textList.value.length; i++) {
        textVoiceList.value.push('http://tsn.baidu.com/text2audio?tok=' + tok.value + '&cuid=' + randomString() + '&ctp=1&lan=zh&per=2&tex=' + encodeURI(textList.value[i]))
      }
      if (textVoiceList.value.length > 0) { // 判断是否有内容
        if (audio.value.paused) {
          audio.value.preload = true
          textList.value.shift()
          audio.value.src = textVoiceList.value.shift()// 每次读数组第一个元素
          audio.value.addEventListener('ended', playEndedHandler, false)
          audio.value.play()
          audio.value.loop = false // 禁止循环，否则无法触发ended事件
        } else {
          audio.value.src = ''
          stop.value = true
        }
      }
    }
    return {
      audio,
      stop,
      voice,
      readNewspaper
    }
  }
}
</script>

<style lang="scss" scoped>
.voice{
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 30px;
  &__voiceIcon{
    font-size: 14px !important;
    &__text{
      font-size: 14px;
    }
  }
}
</style>
