<template>
    <div ref="share" class="share__wrapper">
        <span class="iconfont" @click="handleclickQrCode">&#xe637;</span>
        <div :class="qrcodeHidden?'share__wrapper__qrcode__container':'hidden'">
          <p>扫码分享至朋友圈</p>
          <div class="qrcode" id="qrcode" ref="qrcode">
          </div>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { onMounted, ref } from 'vue'
export default {
  name: 'WechatShare',
  props: {
    newsId: String,
    accessName: String
  },
  setup (props) {
    // const newsContentTag = ref(1)
    const qrcode = ref()
    const share = ref()
    const qrcodeHidden = ref(false)
    const protocol = window.document.location.protocol
    const host = window.document.location.host
    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        if (share.value && !share.value.contains(e.target)) {
          qrcodeHidden.value = false
          if (qrcodeHidden.value !== true) {
            qrcode.value.innerHTML = ''
          }
        }
      })
    })
    const getQrcode = () => {
      const newsId = props.newsId
      const accessName = props.accessName
      // const path = protocol + '//' + host + '/#/components/shareNewsDetail/' + accessName + '/' + newsId
      const path = protocol + '//' + host + '/pc/#/components/shareNewsDetail/' + accessName + '/' + newsId
      // eslint-disable-next-line no-new
      new QRCode(qrcode.value, {
        text: path, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode.value)
    }
    const handleclickQrCode = () => {
      getQrcode()
      qrcodeHidden.value = !qrcodeHidden.value
      if (qrcodeHidden.value !== true) {
        qrcode.value.innerHTML = ''
      }
    }
    // if (typeof window !== 'undefined') {
    //   document.addEventListener('click', (e) => {
    //     console.log(e.target)
    //     // debugger
    //     if (e.target.className !== 'share__wrapper') {
    //       debugger
    //       // show.value = false
    //     }
    //   })
    // }
    return {
      qrcode,
      share,
      qrcodeHidden,
      getQrcode,
      handleclickQrCode
    }
  }
}
</script>
<style lang="scss" scoped>
.share__wrapper{
    position: relative;
    display: inline-block;
    .iconfont{
        font-size: 17px;
        cursor: pointer;
    }
    &__qrcode__container{
        display: block;
        position: absolute;
        text-align: center;
        padding: 10px 6px;
        top: 24px;
        left: 0;
        box-shadow: 0 0 2px #888888;
        background-color: #ffffff;
        p{
          font-size:12px;
        }
        .qrcode{
          display: inline-block;
          img {
            width: 132px;
            height: 132px;
            background-color: #fff;
            padding: 6px;
            box-sizing: border-box;
          }
        }
}
.hidden{
    display: none;
}
}
</style>
