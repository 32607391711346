<template>
    <div ref="periodical" class="periodical">
        <div class="periodical__icon" @click="periodicalToggle">
            <span class="iconfont">&#xe8cf;</span>
        </div>
        <span :class="show?'periodical__point':'hide'"></span>
        <div :class="show?'periodical__wrapper':'hide'">
            <div :style="{ width: '202px', borderRadius: '4px' }">
                 <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link" @click.prevent>
                    {{first}}
                    <DownOutlined />
                    </a>
                    <template #overlay>
                    <a-menu class=" dropdown-scrollbar">
                        <a-menu-item
                            v-for="(item, index) in pageYearList"
                            :key="index"
                            @click="handleClickMenu(item)"
                        >
                        {{item}}
                        </a-menu-item>
                    </a-menu>
                    </template>
                </a-dropdown>
                <ul class="clearfix">
                    <li
                        v-for="(item, index) in oneYearDateList"
                        :key="index"
                        @click="getPageDate(item, index)"
                        :class="activeIndex===index?'active':''"
                    >
                    {{index>8?index+1:'0'+(index+1)}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { get } from '../../utils/request'
import { DownOutlined } from '@ant-design/icons-vue'
export default {
  name: 'Periodical',
  props: {
    newspaperId: String
  },
  components: {
    DownOutlined
  },
  setup (props, { emit }) {
    const show = ref(false)
    const value = ref()
    const pageDate = ref()
    const periodical = ref()
    const pageDateList = ref([])
    const pageYearList = ref([])
    // const pageYearListFirst = ref()
    const oneYearDateList = ref([])
    const activeIndex = ref(0)
    const first = ref()
    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        if (periodical.value && !periodical.value.contains(e.target)) {
          show.value = false
        }
      })
    })
    const periodicalToggle = () => {
      show.value = !show.value
    }
    const getPageDateList = async () => {
      const url = `pageDateController/getPageDateList?newspaperId=${props.newspaperId}`
      const result = await get(url)
      result.list.map((item, index) => {
        const pageDate = item.pageDate
        const pageDateYear = item.pageDate.substr(0, 4)
        pageDateList.value.push(pageDate)
        if (pageYearList.value.indexOf(pageDateYear) === -1) {
          pageYearList.value.push(pageDateYear)
        }
        first.value = pageYearList.value[0]
        if (pageDate.indexOf(first.value) !== -1) {
          oneYearDateList.value.push(pageDate)
        }
      })
      oneYearDateList.value.sort()
      activeIndex.value = oneYearDateList.value.length - 1
      pageDate.value = pageDateList.value[0]
      emit('changePageDate', pageDate.value)
    }
    getPageDateList()
    const handleClickMenu = (year) => {
      const firstDate = ref('')
      first.value = year
      oneYearDateList.value = []
      pageDateList.value.map((item, index) => {
        const pageDate = item
        if (pageDate.indexOf(year) !== -1) {
          oneYearDateList.value.push(pageDate)
        }
      })
      oneYearDateList.value.sort()
      firstDate.value = oneYearDateList.value[0]
      emit('changePageDate', firstDate.value)
      activeIndex.value = 0
    }
    const getPageDate = (date, index) => {
      show.value = false
      activeIndex.value = index
      emit('changePageDate', date)
    }
    const onSelect = (date) => {
      show.value = false
    }
    return {
      show,
      value,
      first,
      periodical,
      pageYearList,
      oneYearDateList,
      activeIndex,
      periodicalToggle,
      onSelect,
      handleClickMenu,
      getPageDate
    }
  }
}
</script>
<style lang="scss" scoped>
.periodical{
    position: absolute;
    top: 200px;
    left: -52px;
    &__icon{
        width: 52px;
        height: 52px;
        background-color: #ffffff;
        border-radius: 4px;
        border: 3px solid #eb4242;
        text-align:center;
        cursor: pointer;
        .iconfont{
            color: #eb4242;
            font-size: 34px;
        }
    }
    &__point{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 7px;
        left: 45px;
        background: url('../../assets/border_point.png') center no-repeat;
    }
    &__wrapper{
        background-color: #ffffff;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 63px;
        width: 202px;
        text-align: center;
        border: 1px solid #f1f1f1;
        ul{
            padding: 0;
            margin: 6px 0 0 18px;
            overflow-y: auto;
            height: 330px;
            li{
                float: left;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #555555;
                margin: 0;
                border: 1px solid #f1f1f1;
                cursor: pointer;
            }
            li:hover{
                background-color: #f1f1f1;
            }
            .active{
                background-color: #f1f1f1;
            }
        }
    }
    .hide{
        display: none;
    }
}
.dropdown-scrollbar {
    height: auto;
    max-height: 300px;
    overflow-x: hidden;
}
</style>
