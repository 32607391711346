<template>
    <div class="selectLink-wrapper">
        <div
          id="selectLinkTip"
          :data-clipboard-text="newsUrl"
          @click="handleClickLink"
        >
        复制链接
        </div>
        <!-- <div>{{newsUrl}}</div> -->
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import Clipboard from 'clipboard'
import { message } from 'ant-design-vue'
export default {
  name: 'SelectLink',
  props: {
    newsId: String,
    accessName: String
  },
  setup (props) {
    const show = ref(false)
    const newsUrl = ref()
    const protocol = window.document.location.protocol
    const host = window.document.location.host
    onMounted(() => {
    //   document.addEventListener('mouseup', (e) => {
    //     if (calender.value && !calender.value.contains(e.target)) {
    //       show.value = false
    //     }
    //   })
    })
    const copy = () => {
      message.info('已复制')
    }
    const copyNO = () => {
      message.info('该浏览器不支持自动复制')
    }
    // 复制某篇稿件地址
    const handleClickLink = () => {
      // newsUrl.value = protocol + '//' + host + '/#/components/shareNewsDetail/' + props.accessName + '/' + props.newsId
      newsUrl.value = protocol + '//' + host + '/pc/#/components/shareNewsDetail/' + props.accessName + '/' + props.newsId
      const clipboard = new Clipboard('#selectLinkTip')
      clipboard.on('success', e => {
        copy()
        clipboard.destroy() // 释放内存
      })
      clipboard.on('error', e => {
        copyNO()
        clipboard.destroy() // 释放内存
      })
      show.value = !show.value
    }
    return {
      show,
      newsUrl,
      handleClickLink
    }
  }
}
</script>
<style lang="scss" scoped>
.selectLink-wrapper{
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #808080;
  cursor: pointer;
.selectLink_wrapper_tip{
    position: absolute;
    top: 23px;
    left: -60px;
    color: #555555;
    width: 200px;
    height: 150px;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0 0 2px #888888;
    white-space: pre-wrap;
  }
}
</style>
