<template>
    <div id="newsDetail__wrapper" class="newsDetail__wrapper">
        <div class="newsDetail__wrapper__logo">
          <Logo />
        </div>
        <div class="newsDetail__wrapper__text">
            <h4 class="newsDetail__wrapper__text__primersTitle">
              {{primersTitle}}
            </h4>
            <h1 class="newsDetail__wrapper__text__title">
              {{title}}
            </h1>
            <h4 class="newsDetail__wrapper__text__subtitle">
              {{subtitle}}
            </h4>
            <div class="newsDetail__wrapper__text__tips">
              <span>日期：{{pageDate}}</span>
              <span>版次：{{pageNum}}</span>
              <span>版名：{{pageName}}</span>
              <!-- <FontScaling
                v-if="newsContent"
                @changeFontSizeAdd="changeFontSizeAdd"
                @changeFontSizeSubtract="changeFontSizeSubtract"
              />
              <WechatShare :newsId="newsId" :accessName="accessName"/>
              <Vioce :voiceText="voiceText"/> -->
            </div>
            <div
              class="newsDetail__wrapper__text__img"
              v-if="typeName == '图片'"
            >
              <img
                :src="`${imgUrl}/${accessName}/${accessName}/${outfilePath}/${fileName}`"
              />
              <p>{{picExplain}}</p>
            </div>
            <div
                v-else
                class="newsDetail__wrapper__text__img"
                v-for="(item, index) in attachedPicturesList"
                :key="index"
            >
                <img
                    :src="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.standardFile}`"
                />
                <div v-html="item.picExplain"></div>
            </div>
            <!-- <img
              class="newsDetail__wrapper__text__img"
              v-for="(item, index) in attachedPictures"
              :key="index"
              :src="`${imgUrl}/${item.accessName}/${item.accessName}/${item.outfilePath}/${item.fileName}`"
            /> -->
            <div
              class="newsDetail__wrapper__text__content"
              ref="text"
              v-html="newsContent"
            >
            </div>
        </div>
    </div>
</template>
<script>
import { get } from '../../utils/request'
import { ref, watch } from 'vue'
import Logo from '../../components/Logo.vue'
// import FontScaling from '../home/FontScaling.vue'
// import WechatShare from '../home/WxShare.vue'
// import Vioce from '../home/Vioce.vue'
import { useRoute } from 'vue-router'
export default {
  name: 'ShareNewsDetail',
  components: { Logo },
  props: {
    newsId: String,
    accessName: String,
    newsContentTag: Number
  },
  setup (props, { emit }) {
    const newsContent = ref()
    const primersTitle = ref()
    const title = ref()
    const subtitle = ref()
    const pageNum = ref()
    const pageName = ref()
    const pageDate = ref()
    const typeName = ref()
    const outfilePath = ref()
    const fileName = ref()
    const picExplain = ref()
    const attachedPictures = ref()
    const attachedPicturesList = ref([])
    const voiceText = ref()
    const count = ref(18)
    const text = ref(null)
    const newsId = ref()
    const accessName = ref()
    const route = useRoute()
    const imgUrl = process.env.VUE_APP_UPLOAD_URL
    const Base64 = require('js-base64').Base64
    const getNewsDetail = async () => {
      if (props.newsId || props.accessName) {
        newsId.value = props.newsId
        accessName.value = props.accessName
      } else {
        newsId.value = route.params.newsId
        accessName.value = route.params.accessName
      }
      const url = `tableNewsController/getTableNews?id=${newsId.value}&accessName=${accessName.value}`
      const result = await get(url)
      primersTitle.value = result.data.primersTitle
      title.value = result.data.title
      document.title = title.value
      subtitle.value = result.data.subtitle
      pageNum.value = result.data.pageNum
      pageName.value = result.data.pageName
      pageDate.value = result.data.pageDate
      typeName.value = result.data.typeName
      outfilePath.value = result.data.outfilePath
      fileName.value = result.data.fileName
      picExplain.value = result.data.picExplain
      attachedPictures.value = result.data.attachedPictures
      attachedPictures.value.map((item, index) => {
        const picExplainItem = Base64.decode(item.picExplain)
        const attachedPicturesItem = {
          accessName: item.accessName,
          outfilePath: item.outfilePath,
          fileName: item.fileName,
          standardFile: item.standardFile,
          picExplain: picExplainItem
        }
        attachedPicturesList.value.push(attachedPicturesItem)
      })
      newsContent.value = Base64.decode(result.data.content)
      voiceText.value = (primersTitle.value + title.value + subtitle.value + newsContent.value).replace(/<[^>]+>/g, '')
      console.log(result)
    }
    getNewsDetail()
    const changeFontSizeAdd = () => {
      if (count.value < 24) {
        count.value += 2
        text.value.style.fontSize = count.value + 'px'
      }
    }
    const changeFontSizeSubtract = () => {
      if (count.value > 16) {
        count.value -= 2
        text.value.style.fontSize = count.value + 'px'
      }
    }
    watch(() => {
      getNewsDetail()
    })
    return {
      primersTitle,
      title,
      subtitle,
      pageDate,
      pageNum,
      pageName,
      typeName,
      attachedPictures,
      attachedPicturesList,
      imgUrl,
      text,
      outfilePath,
      fileName,
      picExplain,
      newsContent,
      voiceText,
      changeFontSizeAdd,
      changeFontSizeSubtract
    }
  }
}
</script>
<style lang="scss" scoped>
.newsDetail__wrapper{
  position: relative;
    padding: 10px 24px 0;
    &__text{
      &__title{
        text-align: center;
      }
      &__primersTitle{
        text-align: center;
      }
      &__subtitle{
        text-align: center;
      }
      &__tips{
        position: relative;
        margin-bottom: 10px;
        border-bottom: 1px solid #dddddd;
        span{
          margin-right: 10px;
          color: #eb4242;
          font-weight: bold;
        }
      }
      &__img{
        width: 90%;
        margin: 0 auto;
        text-align: center;
        img{
          width: 50%;
          margin-bottom: 10px;
        }
      }
      &__content{
        // height: 448px;
        font-size: 18px;
      }
    }
}
</style>
