<template>
    <div ref="mobile" class="mobile">
        <div class="mobile__icon" @click="handleclickQrCode">
            <span class="iconfont">&#xe600;</span>
        </div>
        <span :class="qrcodeHidden?'mobile__point':'hide'"></span>
        <div :class="qrcodeHidden?'mobile__container':'hidden'">
          <p>扫码查看</p>
          <div class="qrcode" id="qrcode" ref="qrcode">
          </div>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'WechatShare',
  setup () {
    const apiUrl = process.env.VUE_APP_API_URL
    // const newsContentTag = ref(1)
    const qrcode = ref()
    const qrcodeHidden = ref(false)
    const mobile = ref()
    const store = useStore()
    const accessName = computed(() => store.state.accessName)
    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        if (mobile.value && !mobile.value.contains(e.target)) {
          qrcodeHidden.value = false
          if (qrcodeHidden.value !== true) {
            qrcode.value.innerHTML = ''
          }
        }
      })
    })
    const getQrcode = () => {
      const path = 'http://szb.yiitai.com/mobile/#/' + accessName.value
      // const path = apiUrl + '/#/' + accessName.value
      // eslint-disable-next-line no-new
      new QRCode(qrcode.value, {
        text: path, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode.value)
    }
    const handleclickQrCode = () => {
      getQrcode()
      qrcodeHidden.value = !qrcodeHidden.value
      if (qrcodeHidden.value !== true) {
        qrcode.value.innerHTML = ''
      }
    }
    return {
      apiUrl,
      qrcode,
      mobile,
      qrcodeHidden,
      getQrcode,
      handleclickQrCode
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile{
    position: absolute;
    top: 280px;
    left: -52px;
    .iconfont{
        font-size: 17px;
        cursor: pointer;
    }
    &__point{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 8px;
        left: 45px;
        background: url('../../assets/border_point.png') center no-repeat;
    }
    &__icon{
        width: 52px;
        height: 52px;
        background-color: #ffffff;
        border-radius: 4px;
        text-align:center;
        border: 3px solid #eb4242;
        cursor: pointer;
        .iconfont{
            color: #eb4242;
            font-size: 34px;
        }
    }
    &__container{
        display: block;
        position: absolute;
        text-align: center;
        padding: 10px 6px;
        top: 0px;
        left: 64px;
        box-shadow: 0 0 2px #888888;
        background-color: #ffffff;
        p{
          font-size:12px;
        }
        .qrcode{
          display: inline-block;
          img {
            width: 132px;
            height: 132px;
            background-color: #fff;
            padding: 6px;
            box-sizing: border-box;
          }
        }
}
.hidden{
    display: none;
}
}
</style>
